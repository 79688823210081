// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1721561000239.7085";
}
// REMIX HMR END

import { Spinner } from "@nextui-org/react";
import { useLoaderData, useNavigate } from "@remix-run/react";
import { useEffect } from "react";
import logo from "~/assets/images/logo.png";
export default function Index() {
  _s();
  const {
    isCentralDomain
  } = useLoaderData();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      isCentralDomain ? navigate("/public") : navigate("/store");
    }, 1500);
  }, []);
  return <div className="p-4 h-screen w-screen dark:bg-gray-950 bg-slate-50 flex justify-center items-center gap-2 flex-col">
      <img src={logo} alt="" className="w-48 mx-auto" />

      <h1 className="text-5xl font-montserrat  text-center">ComClo</h1>

      <Spinner size="lg" className="mt-6" />
    </div>;
}
_s(Index, "5Skb5S+G18ZUsChrmPhwWBr16XE=", false, function () {
  return [useLoaderData, useNavigate];
});
_c = Index;
export const loader = async ({
  request
}) => {
  const domain = request.headers.get("host").split(":")[0];
  console.log(request.headers.get("host").split(":")[0]);
  if (domain == process.env.CENTRAL_DOMAIN) {
    return {
      isCentralDomain: true
    };
  } else {
    return {
      isCentralDomain: false
    };
  }
};
export const meta = () => {
  return [{
    title: "ComClo"
  }, {
    name: "description",
    content: "The best e-Commerce platform for your business."
  }, {
    name: "author",
    content: "KwaminaWhyte"
  }, {
    name: "author",
    content: "Codekid"
  }, {
    name: "og:title",
    content: "ComClo"
  }, {
    name: "og:description",
    content: "The best e-Commerce platform for your business."
  }, {
    name: "og:image",
    content: "https://res.cloudinary.com/app-deity/image/upload/v1700242905/l843bauo5zpierh3noug.png"
  }, {
    name: "og:url",
    content: "https://comclo.printmoney.money"
  }, {
    name: "keywords",
    content: "ecommerce, store, online store, comclo, clothing, fashion, restaurant, food, delivery, online shop, shop, online shopping, shopping, online, business, online business, online platform, platform, online platform, online marketplace, marketplace, online market, market"
  }];
};
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;